import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Mehnateruna is an ancient city in what is now `}<a parentName="p" {...{
        "href": "/Tharsis",
        "title": "Tharsis"
      }}>{`Tharsis`}</a>{`, originally founded by the `}<a parentName="p" {...{
        "href": "/Kingdom%20of%20Lashana",
        "title": "Kingdom of Lashana"
      }}>{`Kingdom of Lashana`}</a>{`.`}</p>
    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "right center horizontal"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/0a427b066ff459c4b757ecad6305fa66/aa440/Morne_ruins_of_an_ancient_desert_city_standing_in_the_city_cent_2806af03-04ce-40f4-9046-4e216657b0a7.png",
            "srcSet": ["/static/0a427b066ff459c4b757ecad6305fa66/5ff7e/Morne_ruins_of_an_ancient_desert_city_standing_in_the_city_cent_2806af03-04ce-40f4-9046-4e216657b0a7.png 375w", "/static/0a427b066ff459c4b757ecad6305fa66/1d69c/Morne_ruins_of_an_ancient_desert_city_standing_in_the_city_cent_2806af03-04ce-40f4-9046-4e216657b0a7.png 750w", "/static/0a427b066ff459c4b757ecad6305fa66/aa440/Morne_ruins_of_an_ancient_desert_city_standing_in_the_city_cent_2806af03-04ce-40f4-9046-4e216657b0a7.png 1500w", "/static/0a427b066ff459c4b757ecad6305fa66/71c1d/Morne_ruins_of_an_ancient_desert_city_standing_in_the_city_cent_2806af03-04ce-40f4-9046-4e216657b0a7.png 1536w"],
            "width": "400px",
            "className": "right center horizontal"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`The Ruins of Mehnateruna after its destruction`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <h2>{`The Destruction of Mehnateruna`}</h2>
    <p>{``}<a parentName="p" {...{
        "href": "/Adam%20La%20Cour",
        "title": "Adam La Cour"
      }}>{`Adam La Cour`}</a>{`, a professor at `}<a parentName="p" {...{
        "href": "/The%20Basentia%20Omnia",
        "title": "The Basentia Omnia"
      }}>{`The Basentia`}</a>{`, took the students `}<a parentName="p" {...{
        "href": "/Rikkart%20Lauten",
        "title": "Rikkart Lauten"
      }}>{`Rikkart Lauten`}</a>{`, `}<a parentName="p" {...{
        "href": "/Jules%20Kaplan",
        "title": "Jules Kaplan"
      }}>{`Jules Kaplan`}</a>{`, `}<a parentName="p" {...{
        "href": "/Shani%20Mirza",
        "title": "Shani Mirza"
      }}>{`Shani Mirza`}</a>{`, `}<a parentName="p" {...{
        "href": "/Faro%20Desjardins",
        "title": "Faro Desjardins"
      }}>{`Faro Desjardins`}</a>{`, and `}<a parentName="p" {...{
        "href": "/Rysha%20Tan%20Mei",
        "title": "Rysha Tan Mei"
      }}>{`Rysha Tan Mei`}</a>{` to the city via a `}<a parentName="p" {...{
        "href": "/Junction",
        "title": "Junction"
      }}>{`Junction`}</a>{` found at the university. There, La Cour inadvertantly enacted a ritual to sever Lauten's `}<a parentName="p" {...{
        "href": "/Thread",
        "title": "Thread"
      }}>{`Thread`}</a>{`, summoning an unknown creature in the process.  `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      